import * as React from "react"
import Layout from "../components/Layout"
import styled from 'styled-components'
import { SEO } from "../components/Seo"
import regulaminDolina from "../assets/regulaminDolinaBugu24.pdf"

const Title = styled.h1`
  text-align: center;
`


const Regulamin = () => {
  return (
    <Layout>
        <Title>REGULAMIN MORE ULTRA RACE DOLINA BUGU 2024</Title>
        <a href={regulaminDolina} title="Regulamin Ultra Race Dolina Bugu">
              <h3>REGULAMIN</h3>
        </a>
    </Layout>
  )
}

export default Regulamin

export const Head = () => (
  <SEO title="Regulamin | Ultramaraton Rowerowy" description="Ultra Race Dolina Bugu to zawody gravelowe wdłuz jednej z ostanich dzikich rzek w Europie. Dwie trasy 290 i 550km"/>
)